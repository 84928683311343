<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title>
        Ver registro

        <v-spacer></v-spacer>
      </v-card-title>

      <v-breadcrumbs :items="routes">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>

    <v-container fluid>
      <v-form>
        <v-row>
          <v-col cols="12" md="8">
            <v-card>
              <v-card-title>
                Información
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <div v-if="register_loading">
                  <v-col>
                    <v-skeleton-loader
                      class="mx-auto"
                      type="table"
                    ></v-skeleton-loader>
                  </v-col>
                </div>

                <div v-else>
                  <v-row>
                    <v-col cols="12" md="10">
                      <v-text-field
                        v-model="register_data.file_file_name"
                        label="Nombre de PDF"
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-btn
                        :disabled="register_data.file_file_name == null"
                        block
                        color="warning"
                        @click="register_data.signed == true ? getFileSigned() : getFile()"
                      >
                        <v-icon small>mdi-file-pdf</v-icon>
                        PDF
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="signatories_headers"
                        :items="register_data.signers"
                        no-data-text="Sin datos"
                        class="elevation-1"
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card>
              <v-card-title>
                Acciones disponibles
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text> Por favor selecciona una opción </v-card-text>

              <v-card-actions>
                <v-btn color="error" @click="closeForm()">
                  <v-icon>mdi-close</v-icon>
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="dialog" scrollable persistent max-width="1000px">
      <v-card>
        <v-card-title>Impresión digital</v-card-title>

        <v-card-text>
          <pdf :src="file_local_url"></pdf>
          <!--
          <embed
            :src="file_local_url"
            width="100%"
            height="600px"
            type="application/pdf"
          />
          -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="red" @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: "RegisterView",
  components: {
    pdf
  },
  data: () => ({
    // Navigation
    routes: [
      {
        text: "Registros",
        disabled: false,
        href: "/register/index",
      },
      {
        text: "Ver",
        disabled: true,
        href: "/register/view",
      },
    ],
    // Form Data
    dialog: false,

    file_loading: null,
    file_local_url: null,

    register_loading: true,
    register_data: null,
    signatories_headers: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Correo", value: "email", sortable: false },
      { text: "RFC", value: "tax_id", sortable: false },
    ],
  }),
  mounted() {
    this.getRegister();
  },
  methods: {
    getRegister() {
      this.register_loading = true;

      this.$axios
        .get("documents/" + this.$route.params.id)
        .then((response) => {
          this.register_data = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.register_loading = false));
    },
    async getFile() {
      this.file_loading = true;

      let headers = {
        Authorization:
          "Basic ZDg1M2Q5Y2M1NDYyZmMxODcxMGFhZTc3YzRkODBhN2NjNmE4ZGE2NTo5S1ZpWDNpNzg5V0xMVU5xZnJndytERWRxWml3WDZjN2xYQkFSd3J4TWQ2a2JFV0QxYkVPYUtlTElFK0hxUjFYUVpQT0pWS0Zvald2NVZkbGN3a0VOdz09",
        responseType: "blob",
      };

      this.$axios
        .get("documents/" + this.$route.params.id + "/file", headers)
        .then((response) => {
          const blob = new Blob([response.data]);
          const objectUrl = URL.createObjectURL(blob);
          this.file_local_url = objectUrl;
          this.dialog = true;
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.file_loading = false));
    },
    async getFileSigned() {
      this.file_loading = true;

      let headers = {
        Authorization:
          "Basic ZDg1M2Q5Y2M1NDYyZmMxODcxMGFhZTc3YzRkODBhN2NjNmE4ZGE2NTo5S1ZpWDNpNzg5V0xMVU5xZnJndytERWRxWml3WDZjN2xYQkFSd3J4TWQ2a2JFV0QxYkVPYUtlTElFK0hxUjFYUVpQT0pWS0Zvald2NVZkbGN3a0VOdz09",
        responseType: "blob",
      };

      this.$axios
        .get("documents/" + this.$route.params.id + "/file_signed", headers)
        .then((response) => {
          const blob = new Blob([response.data]);
          const objectUrl = URL.createObjectURL(blob);
          this.file_local_url = objectUrl;
          this.dialog = true;
          /*
          this.file_local_url = this.loadPdfAsDataUrl(fileURL);
          this.dialog = true;
          */
        })
        .catch((error) => {
          console.log(error.response.data);
        })
        .finally(() => (this.file_loading = false));
    },
    async loadPdfAsDataUrl(file) {
      const url = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => resolve(e.target.result);
      });
      return url;
    },
    closeForm() {
      this.$router.push("/register/index");
    },
  },
  watch: {},
};
</script>

